<template>
  <div class="border-class pa-1">
    <v-row no-gutters align="center">
      <v-col cols="11" class="d-inline-flex">
        <h3>{{ $lang.labels[type] }}{{ stepType === 'REST' && type === 'body' ? ': ' : '' }}</h3>
        <v-radio-group
          v-if="stepType === 'REST' && type === 'body'"
          v-model="bodyType"
          row
          dense
          style="margin-top: 2px"
          @change="changeBodyType"
        >
          <v-radio
            label="JSON"
            value="json"
          ></v-radio>
          <v-radio
            label="Script"
            value="script"
          ></v-radio>
          <v-radio
            label="Template"
            value="template"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col v-if="bodyType === 'json'" cols="1" class="text-right">
        <v-btn
          icon
          :outlined="$vuetify.theme.dark"
          small
          class="color-secondary bg-outline-color"
          :disabled="!canEdit"
          @click="deconstructedData.push({ text: '', value: '' }), editRow(deconstructedData.length - 1)"
        >
          +
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="bodyType === 'json'" no-gutters align="center" class="pt-0">
      <v-col v-if="duplicatedKeys" cols="12" class="pb-2">
        <v-alert dense color="error" style="color: black">{{ $lang.errors.duplicatedKeys }}</v-alert>
      </v-col>
      <v-col v-if="['GROOVY', 'JS', 'PYTHON', 'EXECUTE_PROCESS', 'CSV', 'PLUGIN'].includes(stepType)" cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-card :key="i" flat class="mb-1 background-transparent" style="position: relative">
            <div class="d-inline-flex" style="position: absolute; top: 8px; right: 0">
              <v-btn
                icon
                small
                :disabled="!canEdit"
                @click="editRow(i)"
              >
                <v-icon color="info" small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                class="ml-1"
                icon
                small
                :disabled="!canEdit"
                @click="deleteRow(i)"
              >
                <v-icon small color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
            <v-row no-gutters align="center" class="pb-1">
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.key }}</p>
                <p class="pb-0 mb-0">{{ item.text }}</p>
              </v-col>
              <v-col cols="12" class="my-1">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <p class="pb-0 mb-0 color-primary">{{ $lang.header.value }}</p>
                <p class="pb-0 mb-0">{{ item.value.length > 150 ? `${item.value.substring(0, 147)}...` : item.value }}</p>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
      <v-col v-if="!['GROOVY', 'JS', 'PYTHON', 'EXECUTE_PROCESS', 'CSV', 'PLUGIN'].includes(stepType) && bodyType !== 'json'" cols="12">
        <v-row no-gutters align="center" class="pb-1">
          <v-col v-if="userSettings.display.showId" cols="1">
            <span>{{ $lang.header.id }} </span>
          </v-col>
          <v-col cols="4">
            <span>{{ ['EMAIL', 'SLACK', 'TWILIO', 'PDF'].includes(stepType) ? $lang.header.variable : $lang.header.key }}</span>
          </v-col>
          <v-col cols="5" class="px-1">
            <span>{{ $lang.header.value }}</span>
          </v-col>
          <v-col cols="2" class="text-right">
            <span>{{ $lang.header.act }}</span>
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="!['GROOVY', 'JS', 'PYTHON', 'EXECUTE_PROCESS', 'CSV', 'PLUGIN'].includes(stepType)" class="align-end justify-end" cols="12">
        <template v-for="(item, i) in deconstructedData">
          <v-row :key="i" no-gutters align="end" class="pb-1">
            <v-col v-if="userSettings.display.showId" class="align-self-center">
              <span>{{ i + 1 }}</span>
            </v-col>
            <v-col cols="4" :style="`padding-top: ${ ['REST'].includes(stepType) && ['Content-Type'].includes(item.text) ? '9px' : ''}`">
              <v-combobox
                v-if="['EMAIL', 'SLACK', 'TWILIO', 'PDF', 'REST'].includes(stepType)"
                v-model="item.text"
                full-width
                dense
                :items="filteredKeys"
                :readonly="!canEdit"
                :data-cy="`${type}-key-${i}`"
                @focus="['EMAIL', 'SLACK', 'TWILIO'].includes(stepType) ? removeJavaDollarSign() : ''"
                @change="['EMAIL', 'SLACK', 'TWILIO'].includes(stepType) ? removeJavaDollarSign() : ''"
                @blur="['EMAIL', 'SLACK', 'TWILIO'].includes(stepType) ? removeJavaDollarSign() : ''"
                @input="['EMAIL', 'SLACK', 'TWILIO'].includes(stepType) ? removeJavaDollarSign() : ''"
              />
              <v-text-field
                v-else-if="['EXECUTE_PROCESS', 'GROOVY', 'JS', 'PYTHON', 'SECURITY', 'PLUGIN'].includes(stepType)"
                v-model="item.text"
                dense
                :readonly="!canEdit"
                required
                :rules="[v => !!v || $lang.labels.required, (v) => $options.filters.javaVariableConventionRules(v, true) || $lang.errors.variableJavaWrong]"
                :data-cy="`${type}-key-${i}`"
              />
              <v-text-field
                v-else
                v-model="item.text"
                dense
                :readonly="type === 'switch' || !canEdit"
                required
                :rules="[v => !!v || $lang.labels.required]"
                :data-cy="`${type}-key-${i}`"
              />
            </v-col>
            <v-col cols="5" class="px-1" :style="`padding-top: ${ ['EMAIL', 'SLACK', 'TWILIO', 'PDF'].includes(stepType) && !['locale'].includes(item.text) ? '13px' : ''}`">
              <v-autocomplete
                v-if="['EMAIL', 'SLACK', 'TWILIO', 'REST'].includes(stepType) && ['locale'].includes(item.text)"
                v-model="item.value"
                full-width
                dense
                :items="availableLanguages"
                item-value="value"
                item-text="text"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
                :search-input.sync="searchSync"
              ></v-autocomplete>
              <v-combobox
                v-else-if="['REST'].includes(stepType) && ['Content-Type'].includes(item.text)"
                v-model="item.value"
                full-width
                dense
                :items="headerPredefinedValues"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              />
              <v-text-field
                v-else
                v-model="item.value"
                dense
                required
                :rules="[v => !!v || $lang.labels.required]"
                :readonly="!canEdit"
                :data-cy="`${type}-value-${i}`"
              />
            </v-col>
            <v-col cols="2" class="text-right">
              <div class="d-inline-flex">
                <v-btn
                  v-if="['js', 'groovy'].includes(type) || ['REST', 'JWT', 'EMAIL', 'SMTP', 'EXECUTE_PROCESS'].includes(stepType)"
                  icon
                  small
                  :disabled="!canEdit"
                  @click="editRow(i)"
                >
                  <v-icon small color="info">mdi-pencil</v-icon>
                </v-btn>
                <v-btn
                  class="ml-1"
                  icon
                  small
                  :disabled="!canEdit"
                  @click="deleteRow(i)"
                >
                  <v-icon small color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
              </div>
            </v-col>
            <v-col cols="12">
              <v-divider></v-divider>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>
    <v-row v-if="bodyType === 'script'" no-gutters align="center" class="pt-2 position-relative">
      <v-textarea
        v-model="bodyScript"
        data-cy="rest-bodyScript"
        rows="2"
        outlined
        hide-details
        dense
        :label="$lang.labels.bodyScript"
        :readonly="!canEdit"
      />
      <v-btn
        class="button-help"
        style="position: absolute; top: 22px; right: 10px"
        icon
        small
        :disabled="!canEdit"
        @click="$emit('bodyScriptEditor', bodyScript)"
      >
        <v-icon small color="info">mdi-pencil</v-icon>
      </v-btn>
    </v-row>
    <v-row v-if="bodyType === 'template'" no-gutters align="center" class="pt-2">
      <v-col cols="12">
        <div class="d-inline-flex" style="width: 100%">
          <v-autocomplete
            v-model="bodyTemplateData.textTemplate"
            outlined
            dense
            :items="templates"
            :loading="isLoadingTemplates"
            :search-input.sync="searchTemplates"
            clearable
            hide-no-data
            item-text="name"
            item-value="name"
            :label="$lang.labels.textTemplate"
            :placeholder="$lang.actions.startTyping"
            prepend-inner-icon="mdi-cloud-search-outline"
            :readonly="!canEdit"
            :rules="[v => !!v || $lang.labels.required]"
            class="required-asterisk"
          ></v-autocomplete>
          <v-btn
            icon
            light
            color="primary"
            class="ml-1"
            @click="searchTextTemplatesFunction(searchTemplates)"
          >
            <v-icon
              dense
              small
            >
              mdi-refresh
            </v-icon>
          </v-btn>
          <v-btn
            text
            class="ml-1"
            color="primary"
            :disabled="!templateId"
            @click="openTemplate()"
          >
            {{ $lang.actions.openTemplate }}
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="pb-2">
        <v-autocomplete
          v-model="bodyTemplateData.locale"
          outlined
          dense
          :items="availableLanguages"
          item-value="value"
          item-text="text"
          :label="$lang.labels.locale"
          :readonly="!canEdit"
          :rules="[v => !!v || $lang.labels.required]"
          class="required-asterisk"
        ></v-autocomplete>
      </v-col>
      <v-col v-if="bodyTemplateData.textTemplate" cols="12" class="pb-2">
        <add-key-value
          type="variables"
          :step-type="stepType"
          :can-edit="canEdit"
          :data="bodyTemplateData.variables"
          :single-step="singleStep"
          :text-template-name="bodyTemplateData.textTemplate"
          @dataChanged="bodyTemplateData.variables = $event"
        />
      </v-col>
    </v-row>
    <v-dialog v-if="showJsGroovyEditor" v-model="showJsGroovyEditor" max-width="71%">
      <global-js-groovy-editor
        :item="selectedItem"
        :with-buttons="true"
        :can-edit="canEdit"
        :vars="steps"
        :step-type="stepType"
        :single-step="singleStep"
        @closeDialog="closeEdit()"
        @fromGlobalEditor="updateFromEditor($event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import globalJsGroovyEditor from '@/components/ui/GlobalJsGroovyEditor.vue'
import {
  getTextTemplateByIdUsingGET as getTemplate,
  getTextTemplatesUsingGET as getTemplates
} from '@/utils/api'
import AddKeyValue from './KeyValPairModal'
import { isEqual } from 'lodash'

export default {
  name: 'AddKeyValue',
  components: { AddKeyValue, globalJsGroovyEditor },
  props: {
    steps: {
      type: Array,
      default: () => {
        return []
      }
    },
    data: {
      type: Object,
      default: () => {
        return null
      }
    },
    type: {
      type: String,
      default: () => {
        return ''
      }
    },
    stepType: {
      type: String,
      default: () => {
        return ''
      }
    },
    full: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    textTemplateName: {
      type: String,
      default: () => {
        return ''
      }
    },
    singleStep: {
      type: Object,
      default: () => {
        return null
      }
    },
    keepNumbers: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    lock: true,
    templateId: 0,
    isLoadingTemplates: false,
    templates: [],
    searchTemplates: '',
    bodyType: 'json',
    showJsGroovyEditor: false,
    deconstructedData: [],
    selectedItem: null,
    selectedKeys: [],
    emailKeys: ['locale', 'subject'],
    slackKeys: ['locale', 'message'],
    pdfKeys: [],
    restKeys: [],
    filteredKeys: [],
    duplicatedKeys: false,
    languages: ['bg', 'zh', 'hr', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'hu', 'ga', 'it', 'lv', 'lt', 'mt', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk'],
    searchSync: '',
    bodyScript: '',
    headerPredefinedValues: [
      '\'application/json\'',
      '\'application/xml\'',
      '\'application/x-www-form-urlencoded\'',
      '\'multipart/form-data\''
    ],
    bodyTemplateData: {
      textTemplate: '',
      locale: 'en',
      variables: {}
    }
  }),
  computed: {
    ...mapState('app', ['userSettings']),
    availableLanguages() {
      return this.languages.map((x) => {
        return { text: this.$lang.labels[x], value: x }
      })
    }
  },
  watch: {
    searchTemplates: {
      handler(val) {
        if (!this.lock && val && val.length > 1) {
          this.searchTextTemplatesFunction(val)
        } else {
          this.templateId = 0
        }
      }
    },
    textTemplateName: {
      handler(val) {
        this.emailKeys = ['locale', 'subject']
        this.slackKeys = ['locale', 'message']
        this.pdfKeys = []
        this.fetchTemplates(val)
      },
      immediate: true
    },
    searchSync: {
      handler(val) {
        const tempSearch = this.availableLanguages.find((x) => x.text === val)
        const localeData = this.deconstructedData.find((x) => x.text === 'locale')

        if (tempSearch) localeData.value = tempSearch.value
      }
    },
    bodyScript: {
      handler(val) {
        this.$emit('dataBodyScriptChanged', val)
      }
    },
    bodyTemplateData: {
      handler(val) {
        if (!this.lock) {
          this.$emit('dataBodyTemplateChanged', val)
        }
      },
      deep: true
    },
    deconstructedData: {
      handler() {
        let tempData = null

        if (this.type !== 'switch') {
          tempData = {}

          this.deconstructedData.forEach((item) => {
            if (!tempData[item.text]) tempData[item.text] = item.value
          })

          if (this.keepNumbers) {
            Object.entries(tempData).forEach(([key, val]) => {
              if (val && !isNaN(val)) {
                tempData[key] = Number(val)
              }
            })
          }
        } else {
          tempData = []

          this.deconstructedData.forEach((item) => {
            if (item.text && item.value) tempData.push({ query: item.value, steps: item.steps ? item.steps : [] })
          })
        }
        this.selectedKeys = this.deconstructedData.map((x) => x.text)
        this.duplicatedKeys = !!this.hasDuplicates(this.selectedKeys)
        this.$emit('dataChanged', tempData)
      },
      deep: true
    },
    data: {
      handler(val) {
        if (val) {
          const newDeconstructedData = []

          if (this.type === 'switch') {
            const tempData = this.data.value
  
            tempData.forEach((item) => {
              newDeconstructedData.push({ text: 'query', value: item.query, steps: item.steps })
            })

            return
          }
          if (this.stepType === 'REST' && !this.data.bodyScript && !this.data.bodyTemplate) {
            if (this.type === 'body') {
              for (const row in this.data.body) {
                newDeconstructedData.push({ text: row , value: this.data.body[row] })
              }
            } else if (['headers', 'queryParams', 'variables'].includes(this.type)) {
              for (const row in this.data) {
                newDeconstructedData.push({ text: row , value: this.data[row] })
              }
            }
          } else if (this.stepType !== 'REST') {
            for (const row in this.data) {
              // eslint-disable-next-line no-prototype-builtins
              if (this.data.hasOwnProperty(row)) {
                newDeconstructedData.push({ text: row , value: this.data[row] })
              }
            }
          }
          
          if (!isEqual(this.deconstructedData, newDeconstructedData)) {
            this.deconstructedData = [...newDeconstructedData]
          }
        }
      },
      deep: true
    },
    selectedKeys: {
      handler(val) {
        this.filteredKeys = this.filteredKeys.filter((x) => !val.includes(x))
      }
    }
  },
  async mounted() {
    if (this.data && this.type === 'switch') {
      const tempData = this.data.value

      tempData.forEach((item) => {
        this.deconstructedData.push({ text: 'query', value: item.query, steps: item.steps })
      })
    } else if (this.data && this.stepType === 'REST' && this.type === 'body') {
      if (this.data.bodyScript) {
        this.bodyType = 'script'
        this.deconstructedData = []
        this.bodyTemplateData = null
        this.bodyScript = this.data.bodyScript
      } else if (this.data.bodyTemplate) {
        
        this.bodyType = 'template'
        this.bodyScript = ''
        setTimeout(() => {
          this.deconstructedData = []
        }, 30)
      } else {
        this.bodyType = 'json'
        for (const row in this.data.body) {
        // eslint-disable-next-line no-prototype-builtins
          if (this.data.body.hasOwnProperty(row)) {
            this.deconstructedData.push({ text: row , value: this.data.body[row] })
          }
        }
      }
    } else {
      for (const row in this.data) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.data.hasOwnProperty(row)) {
          this.deconstructedData.push({ text: row , value: this.data[row] })
        }
      }
    }
    if (this.data?.bodyTemplate) {
      this.bodyTemplateData = this.data.bodyTemplate
      
      this.searchTemplates = this.bodyTemplateData.textTemplate
      await this.fetchTemplates(this.bodyTemplateData.textTemplate)
    }
    this.lock = false
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),
    searchTextTemplatesFunction(val = '') {
      this.isLoadingTemplates = true

      const obj = {}

      if (val && val.length > 1) obj.name = val

      this.fetchTemplates(val)
    },
    async fetchTemplates(val = '') {
      if (!this.textTemplateName && !val) return

      const obj = {
        page: 1,
        size: 25,
        name: val || this.textTemplateName,
        status: 'ACTIVE'
      }

      try {
        const templatesListRes = await getTemplates(obj)
        const templates = [...templatesListRes.data?.data?.items || []]

        this.templates = templates
        this.isLoadingTemplates = false

        const isSelected = templates.find((x) => x.name === this.searchTemplates)

        if (isSelected) this.templateId = isSelected.id
        const templateWithId = templates.find((x) => x.name === (this.textTemplateName || val)) || null

        if (templateWithId) {
          const templateRes = await getTemplate({ templateId: templateWithId.id })

          if (templateRes.data?.data?.exampleVariables && Object.keys(templateRes.data.data.exampleVariables).length > 0) {

            if (this.stepType === 'PDF') 
              this.pdfKeys = Object.keys(templateRes.data.data.exampleVariables)

            if (this.stepType === 'EMAIL') {
              this.emailKeys = Object.keys(templateRes.data.data.exampleVariables)
                .map((key) => this.removeJavaDollarSignReturn(key))
              this.filteredKeys =  this.emailKeys.filter((x) => !this.selectedKeys.includes(x))
              
            }

            if (this.stepType === 'SLACK') {
              this.slackKeys = Object.keys(templateRes.data.data.exampleVariables)
                .map((key) => this.removeJavaDollarSignReturn(key))
                
              this.filteredKeys =  this.slackKeys.filter((x) => !this.selectedKeys.includes(x))
            }

            if (this.stepType === 'TWILIO') {
              this.slackKeys = Object.keys(templateRes.data.data.exampleVariables)
                .map((key) => this.removeJavaDollarSignReturn(key))
              this.filteredKeys =  this.slackKeys.filter((x) => !this.selectedKeys.includes(x))
            }

            if (this.stepType === 'PDF') this.filteredKeys =  this.pdfKeys.filter((x) => !this.selectedKeys.includes(x))
            if (this.stepType === 'REST') {
              this.restKeys = Object.keys(templateRes.data.data.exampleVariables)
              this.filteredKeys =  this.restKeys.filter((x) => !this.selectedKeys.includes(x))
            }
          }
        }
      } catch (error) {
        this.err = error
        setTimeout(() => this.err = '', 5000)
        console.log(error)
      }
    },
    updateFromEditor(data) {
      this.deconstructedData[this.selectedItemIndex].text = data.key
      this.deconstructedData[this.selectedItemIndex].value = data.value
      this.showJsGroovyEditor = false
      this.selectedItemIndex = null
    },
    removeJavaDollarSignReturn(text) {
      const isDollarStart = text.substring(0, 1) === '$'

      const isDotSecond = text.substring(1, 2) === '.'

      if (isDollarStart && isDotSecond) return text.substring(2)

      if (isDollarStart && !isDotSecond) return text.substring(1)

      return text
    },
    removeJavaDollarSign() {
      this.deconstructedData.forEach((obj, i) => {
        const isDollarStart = obj.text.substring(0, 1) === '$'

        const isDotSecond = obj.text.substring(1, 2) === '.'

        if (isDollarStart && isDotSecond) this.deconstructedData[i].text = obj.text.substring(2)

        if (isDollarStart && !isDotSecond) this.deconstructedData[i].text = obj.text.substring(1)
      })
    },
    hasDuplicates(arr) {
      return arr.some((x) => arr.indexOf(x) !== arr.lastIndexOf(x))
    },
    //TODO change to the function from helpers
    emailRules(v) {
      const isSplit = v.split(',')

      if (isSplit.length > 1) {
        let correct = true

        isSplit.forEach((testEmail) => {
          if (correct) {
            correct = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(testEmail.trim().toLowerCase())
          }
        })

        if (correct) return true

        return this.$lang.errors.formatEmail
      } else {
        return (v && /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v.toLowerCase())) || this.$lang.errors.formatEmail
      }
    },
    deleteRow(i) {
      this.deconstructedData.splice(i, 1)
    },
    editRow(i) {
      if (['JS', 'GROOVY', 'PYTHON', 'EXECUTE_PROCESS', 'CSV', 'PLUGIN'].includes(this.stepType) 
      || ((this.stepType === 'REST' && this.bodyType === 'json') && !this.textTemplateName)) {
        this.selectedItem = this.deconstructedData[i]
        this.selectedItemIndex = i
        this.showJsGroovyEditor = true
      } else {
        this.$emit('editor', this.deconstructedData[i])
      }
    },
    closeEdit() {
      if (this.selectedItem?.value === '' && this.selectedItem?.text === '') {
        this.deconstructedData.splice(this.selectedItemIndex, 1)
      }
      this.selectedItem = null
      this.selectedItemIndex = null
      this.showJsGroovyEditor = false
    },
    changeBodyType() {
      if (this.bodyType === 'json') {
        this.deconstructedData = []
        this.bodyTemplateData = null
        this.bodyScript = ''
      } else if (this.bodyType === 'script') {
        this.bodyScript = ''
      } else if (this.bodyType === 'template') {
        this.deconstructedData = []
        this.bodyTemplateData = {
          textTemplate: '',
          locale: 'en',
          variables: {}
        }
        this.bodyScript = ''
      }
    },
    openTemplate() {
      if (this.templateId) window.open(`/${localStorage.selectedLanguage || 'en'}/templates/templates/edit/${this.templateId}`, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
.border-class {
  border: 1px var(--v-customInputBorderColor-base) solid;
  border-radius: 6px;
}
</style>
